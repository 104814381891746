.boardContainer{
    width: 50vh;
    max-width: 50vw;
    border: 2px black solid;
}

@media screen and (max-height: 800px){
    .boardContainer{
        width: 50vh;
        max-width: 40vw;
        border: none;
    }
}

@media screen and (min-width: 1500px){
    .boardContainer{
        width: 80vh;
        max-width: 40vw;
        border: none;
    }
}